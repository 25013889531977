


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48978.22415714176!2d71.61521389239542!3d22.72568566398473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395940d8263e8021%3A0xe360d55e1c908ef0!2sParivartan%20Koli%20Career%20Academy!5e0!3m2!1sen!2sin!4v1668014414466!5m2!1sen!2sin"></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;