import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const name = "Rajib Raj";
const degi = "Assistant Teacher";
const desc =
  "I'm an Afro-Latina digital artist originally from Long Island, NY. I love to paint design and photo manpulate in Adobe Photoshop while helping others learn too. Follow me on Instagram or tweet me";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-youtube",
    className: "youtube",
  },
];

const Author = ({ authorData }) => {
  const navigate = useNavigate();
  const [color, setColor] = useState("#101115");

  const handleClickToNavigate = (e) => {
    e.preventDefault();
    navigate(`/teacher-single?teacher_id=${authorData.id}`);
  };

  const handleMouseEnter = (e) => {
    e.preventDefault();
    setColor("#F16126");
  };

  const handleMouseLeave = (e) => {
    e.preventDefault();
    setColor("#101115");
  };

  return (
    <div
      className="authors"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="author-thumb">
        <img
          src={authorData.photo}
          alt={authorData.name}
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </div>
      <div className="author-content">
        {/* <Link
          to={{
            pathname: "/teacher-single",
            search: `teacher_id=${authorData.id}`,
          }}
          style={{ width: "100%" }}
        > */}
        <h5
          onClick={handleClickToNavigate}
          style={{ cursor: "pointer", color: `${color}` }}
        >
          {authorData.name}
        </h5>
        {/* </Link> */}
        <span>{authorData.designation}</span>
        <p>{authorData.personal_statement}</p>
        <ul className="lab-ul social-icons">
          {socialList.map((val, i) => {
            if (authorData[val[`className`]]) {
              return (
                <li key={i}>
                  <a
                    href={authorData[val[`className`]]}
                    className={val.className}
                  >
                    <i className={val.iconName}></i>
                  </a>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default Author;
