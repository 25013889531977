import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
//Imports
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import Receipt from "./receipt";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
// import { useNavigate } from "react-router-dom";
import ModalWindow from "../component/modalWindow";
import LoadingSpinner from "../component/sidebar/loading-spinner";
import { redirect } from "react-router-dom";

////--------functions for razorpay starts------------------
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

////--------functions for razorpay ends----------------------

const AddmissionForm = () => {
  // const navigate = useNavigate();
  const [settingData, setSettingData] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  //for details-----------------------------
  const [validated, setValidated] = useState(false);
  const [aadhar, setAadhar] = useState("");
  const [enrollment_no, setEnrollment_no] = useState("");
  const [education, setEducation] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [data, setData] = useState({
    surname: "",
    student_name: "",
    student_phone: "",
    student_whatsapp_no: "",
    parents_name: "",
    parents_phone: "",
    parent_whatsapp_no: "",
    relation_ship: "",
    other_relation_ship: "",
    gender: "",
    email: "",
    birth_date: "",

    parivart_student: "",
    enrollment_number: "",

    current_address_line1: "",
    current_address_line2: "",
    current_picode: "",
    current_village: "",
    current_taluka: "",
    current_district: "",
    current_state: "",

    permanent_address_line1: "",
    permanent_address_line2: "",
    permanent_pincode: "",
    permanent_village: "",
    permanent_taluka: "",
    permanent_district: "",
    permanent_state: "",

    education_id: "",
    twelfth_percentage: "",
    graduate_percentage: "",
    other_study: "",
    other_study_percentage: "",
    course_id: "",
    terms_and_condition: false,

  });
  const [pinDataOfAddress, setPinDataOfAddress] = useState([]);
  const [pinDataOfPermanentAddress, setPinDataOfPermanentAddress] = useState(
    []
  );
  const [showPermanentAddress, setShowPermanentAddress] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const [showRazorpayAlert, setShowRazorpayAlert] = useState(false);
  const [showPaymentAlert, setShowPaymentAlert] = useState(false);
  const [paymentAlertBody, setPaymentAlertBody] = useState("");
  const [showPrintAlert, setShowPrintAlert] = useState(false);
  const [printAlertBody, setPrintAlertBody] = useState("");

  //-----------------------------------------

  //for image and pdf upload-----------------
  const [images, setImages] = useState({
    studentPassportImage: "",
    casteImage: "",
  });
  const [imagesError, setImagesError] = useState({
    studentPassportImage: "",
    casteImage: "",
  });
  const [imagesPreview, setImagesPreview] = useState({
    studentPassportImage: "",
    casteImage: "",
  });

  const [receiptData, setReceiptData] = useState("");
  const [isPayFeesDisabled, setIsPayFeesDisabled] = useState(false);
  const [isShowReceiptDisabled, setIsShowReceiptDisabled] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      const responseSetting = await axios.get(
        "https://parivartan.phpicoder.com/api/get_setting"
      );
      // console.log(responseSetting);
      if (responseSetting.data.status) {
        setSettingData(responseSetting.data.data);
      }
    };

    fetchSettings();

    const fetchCourse = async () => {
      const response = await axios.get(
        `https://parivartan.phpicoder.com/api/get_course`
      );
      // console.log(response);
      if (response.data.status) {
        response.data.data.unshift({ id: "", name: "Select Course" });
        setCourseData(response.data.data);
      }
    };

    fetchCourse();

    const fetchEducation = async () => {
      const response = await axios.get(
        `https://parivartan.phpicoder.com/api/get_education`
      );
      // console.log(response);
      if (response.data.status) {
        setEducation(response.data.data);
      }
    };
    fetchEducation();

    // // ==============Print========================
    // if (receiptData !== "") {
    //   const fetchPaymentResponse = async () => {
    //     const razorpayform = new FormData();
    //     razorpayform.append("payment_id", receiptData.razorpay_payment_id);

    //     try {
    //       const paymentResponse = await axios.post(
    //         "https://parivartan.phpicoder.com/api/razorpay_payment",
    //         razorpayform,
    //         {
    //           headers: {
    //             "Content-Type": "multipart/form-data",
    //           },
    //           mode: "no-cors",
    //         }
    //       );

    //       if (paymentResponse.data.captured) {
    //         // alert(`payment Successfull`);
    //         setPaymentAlertBody(`Payment Successfull!
    //         Printing Application Form.
    //         `);
    //         setShowPaymentAlert(true);

    //         const getEnrollment = new FormData();
    //         getEnrollment.append("enrollment_no", enrollment_no);

    //         const responseGetEnrollment = await axios.post(
    //           "https://parivartan.phpicoder.com/api/get_enrollment",
    //           getEnrollment,
    //           {
    //             headers: {
    //               "Content-Type": "multipart/form-data",
    //             },
    //             mode: "no-cors",
    //           }
    //         );

    //         const { created_at } = paymentResponse.data;
    //         // Convert to IST
    //         const date = new Date(created_at * 1000);
    //         const ISTDateString = date.toLocaleString("en-IN", {
    //           timeZone: "Asia/Kolkata",
    //         });

    //         if (responseGetEnrollment.data.status) {
    //           const dataForPdf = {
    //             studentFirstName: responseGetEnrollment.data.data.surname,
    //             studentLastName: responseGetEnrollment.data.data.student_name,
    //             studentRollNo: `${responseGetEnrollment.data.data.enrollment_no}`,
    //             studentEmail: responseGetEnrollment.data.data.email,
    //             studentMobile: responseGetEnrollment.data.data.student_phone,
    //             studentPhoto: responseGetEnrollment.data.data.photo,
    //             courseName: responseGetEnrollment.data.data.course.name,
    //             courseFees: responseGetEnrollment.data.data.course.price,
    //             courseDuration: responseGetEnrollment.data.data.course.duration,
    //             courseID: responseGetEnrollment.data.data.course.id,
    //             ISTDateString,
    //           };

    //           const printElement = await ReactDOMServer.renderToString(
    //             <Receipt data={dataForPdf} />
    //           );

    //           const opt = {
    //             margin: [5, 5, 5, 5],
    //             filename: `${responseGetEnrollment.data.data.surname}-${responseGetEnrollment.data.data.enrollment_no}`,
    //             html2canvas: { scale: 2 },
    //             jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    //           };

    //           await html2pdf().from(printElement).set(opt).save();
    //         }
    //       }

    //       // if (!paymentResponse.data.captured) {
    //       //   setPaymentAlertBody(`Payment Failed! Contact Admin.`);
    //       //   setShowPaymentAlert(true);
    //       // }
    //       // navigate("/addmission");
    //       //window.location.reload();
    //       // ---------------------------------------------------
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };

    //   fetchPaymentResponse();
    // } else {
    //   return;
    // }
    // return;

    // ==============Print=======================
  }, [receiptData]);

  ////----------variables for razorpay ends-----------------------

  //event handlers for details----------------------
  const handleAadhaarNumber = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = inputValue.replace(/(\d{4})/g, "$1 "); // Insert space after every four digits

    // setValue(formattedValue.slice(0, 19)); // Limit input to 16 digits
    setAadhar(formattedValue.slice(0, 19));

    // Additional logic can be added here to handle the formatted value
  };

  const handleChange = (props) => async (e) => {
    e.preventDefault();
    setData({ ...data, [props]: e.target.value });

    if (props === "current_picode" && e.target.value.length !== 6) {
      setPinDataOfAddress([[{ Name: "Select City/Village" }]]);
      setData({
        ...data,
        current_picode: e.target.value,
        current_district: "",
        current_state: "",
        current_taluka: "",
      });
      return;
    }

    if (props === "permanent_pincode" && e.target.value.length !== 6) {
      setPinDataOfPermanentAddress([[{ Name: "Select City/Village" }]]);
      setData({
        ...data,
        permanent_pincode: e.target.value,
        permanent_district: "",
        permanent_state: "",
        permanent_taluka: "",
      });
      return;
    }

    if (props === "current_picode" && e.target.value.length === 6) {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${e.target.value}`,
        {
          mode: "no-cors",
        }
      );

      if (response.data[0].PostOffice === null) {
        setPinDataOfAddress([[{ Name: "Enter Correct PIN" }]]);
        setData({
          ...data,
          current_picode: e.target.value,
          current_district: "",
          current_state: "",
          current_taluka: "",
        });
        return;
      }
      setData({
        ...data,
        current_picode: e.target.value,
        current_district: response.data[0].PostOffice[0].District,
        current_state: response.data[0].PostOffice[0].State,
        current_taluka: response.data[0].PostOffice[0].Block,
      });
      response.data[0].PostOffice.unshift({ Name: "Select City/Village" });
      setPinDataOfAddress([response.data[0].PostOffice]);
    }
    if (props === "permanent_pincode" && e.target.value.length === 6) {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${e.target.value}`,
        {
          mode: "no-cors",
        }
      );

      if (response.data[0].PostOffice === null) {
        setPinDataOfPermanentAddress([[{ Name: "Enter Correct PIN" }]]);
        setData({
          ...data,
          permanent_pincode: e.target.value,
          permanent_district: "",
          permanent_state: "",
          permanent_taluka: "",
        });
        return;
      }
      setData({
        ...data,
        permanent_pincode: e.target.value,
        permanent_district: response.data[0].PostOffice[0].District,
        permanent_state: response.data[0].PostOffice[0].State,
        permanent_taluka: response.data[0].PostOffice[0].Block,
      });
      response.data[0].PostOffice.unshift({ Name: "Select City/Village" });
      setPinDataOfPermanentAddress([response.data[0].PostOffice]);
    }
  };

  const handleChangeTC = (e) => {
    setData({ ...data, terms_and_condition: e.target.checked });
  };

  const handleChangeSameAsAbove = (e) => {
    setShowPermanentAddress(!showPermanentAddress);
    if (e.target.checked) {
      console.log(data);
      setData({
        ...data,
        permanent_address: data.permanent_address,
        permanent_address_line1: data.current_address_line1,
        permanent_address_line2: data.current_address_line2,
        permanent_village: data.current_village,
        permanent_taluka: data.current_taluka,
        permanent_pincode: data.current_picode,
        permanent_district: data.current_district,
        permanent_state: data.current_state,
      });
    }
    if (!e.target.checked) {
      setData({
        ...data,
        permanent_address: "",
        permanent_address_line1: "",
        permanent_address_line2: "",
        permanent_village: "",
        permanent_taluka: "",
        permanent_pincode: "",
        permanent_district: "",
        permanent_state: "",
      });
    }
  };

  //------------------------------------------------

  //event handlers for image pdf upload-------------
  const handleImageChange = (props) => (event) => {
    event.preventDefault();

    const imageFile = event.target.files[0];

    if (imageFile && /(jpe?g|png)$/i.test(imageFile.type)) {
      if (event.target.id === "studentPassportImage") {
        if (imageFile.size >= 1048576) {
          setImagesPreview({ ...imagesPreview, [props]: null });
          setImagesError({
            ...imagesError,
            [props]: "File Should be less than 1MB of size",
          });
          return;
        }
      }
      if (
        event.target.id !== "studentPassportImage" &&
        event.target.id !== "studentSignatureImage"
      ) {
        if (imageFile.size >= 3145728) {
          setImagesPreview({ ...imagesPreview, [props]: null });
          setImagesError({
            ...imagesError,
            [props]: "File Should be less than 3MB of size",
          });
          return;
        }
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagesPreview({ ...imagesPreview, [props]: reader.result });
      };
      reader.readAsDataURL(imageFile);
      setImagesError({ ...imagesError, [props]: "" });
      setImages({ ...images, [props]: imageFile });
    } else {
      event.stopPropagation();
      event.target.value = null; // This will clear the input selection
      setImagesPreview({ ...imagesPreview, [props]: null });
      setImagesError({
        ...imagesError,
        [props]: "Please select a JPEG or PNG file",
      });
      setImages({ ...images, [props]: "" });
    }
  };

  //------------------------------------------------

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(data);

    const form = event.currentTarget;

    // =================================================================
    if (form.checkValidity() === false) {
      setShowAlert(true);
    }
    if (form.checkValidity() === true && !data.terms_and_condition) {
      setShowAlert(true);
    }
    if (form.checkValidity() === true && data.terms_and_condition) {
      try {
        const addAdmission = new FormData();
        addAdmission.append("surname", data.surname);
        addAdmission.append("student_name", data.student_name);
        addAdmission.append("student_phone", data.student_phone);
        addAdmission.append("student_whatsapp_no", data.student_whatsapp_no);
        addAdmission.append("parents_name", data.parents_name);
        addAdmission.append("parents_phone", data.parents_phone);
        addAdmission.append("parent_whatsapp_no", data.parent_whatsapp_no);
        addAdmission.append("relation_ship", data.relation_ship);
        addAdmission.append("other_relation_ship", data.other_relation_ship);
        addAdmission.append("gender", data.gender);
        addAdmission.append("email", data.email);
        addAdmission.append("birth_date", data.birth_date);
        addAdmission.append("parivart_student", data.parivart_student);
        addAdmission.append("parivart_old_enrollment", data.enrollment_no);
        addAdmission.append("aadhar_no", aadhar);

        addAdmission.append("current_address_line1", data.current_address_line1);
        addAdmission.append("current_address_line2", data.current_address_line2);
        addAdmission.append("current_picode", data.current_picode);
        addAdmission.append("current_village", data.current_village);
        addAdmission.append("current_taluka", data.current_taluka);
        addAdmission.append("current_district", data.current_district);
        addAdmission.append("current_state", data.current_state);

        addAdmission.append("permanent_address_line1", data.permanent_address_line1);
        addAdmission.append("permanent_address_line2", data.permanent_address_line2);
        addAdmission.append("permanent_pincode", data.permanent_pincode);
        addAdmission.append("permanent_village", data.permanent_village);
        addAdmission.append("permanent_taluka", data.permanent_taluka);
        addAdmission.append("permanent_district", data.permanent_district);
        addAdmission.append("permanent_state", data.permanent_state);

        addAdmission.append("twelfth_percentage", data.twelfth_percentage);
        addAdmission.append("graduate_percentage", data.graduate_percentage);
        addAdmission.append("other_study", data.other_study);
        addAdmission.append("other_study_percentage", data.other_study_percentage);
        addAdmission.append("course_id", data.course_id);
        addAdmission.append("terms_and_condition", data.terms_and_condition);

        addAdmission.append("photo", images.studentPassportImage);
        addAdmission.append("caste_certificate", images.casteImage);

        const responseAddAdmission = await axios.post(
          "https://parivartan.phpicoder.com/api/add_admission",
          addAdmission
        );

        if (!responseAddAdmission.data.status) {
          throw new Error(responseAddAdmission.data.data);
        } else {
          setEnrollment_no(responseAddAdmission.data.data.enrollment_no);

          setPrintAlertBody(`Fee waiver! Printing Application Form.`);

          const getEnrollment = new FormData();
          getEnrollment.append(
            "enrollment_no",
            responseAddAdmission.data.data.enrollment_no
          );
          const responseGetEnrollment = await axios.post(
            "https://parivartan.phpicoder.com/api/get_enrollment",
            getEnrollment
          );

          if (!responseGetEnrollment.data.status) {
            throw new Error(responseGetEnrollment.data.message);
          }

          if (responseGetEnrollment.data.status) {

            setShowSuccessModal(true);

            const date = new Date();
            const ISTDateString = date.toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
            });

            const dataForPdf = {
              studentFirstName: responseGetEnrollment.data.data.surname,
              studentLastName: responseGetEnrollment.data.data.student_name,
              studentRollNo: `${responseGetEnrollment.data.data.enrollment_no}`,
              studentEmail: responseGetEnrollment.data.data.email,
              studentMobile: responseGetEnrollment.data.data.student_phone,
              studentPhoto: responseGetEnrollment.data.data.photo,
              courseName: responseGetEnrollment.data.data.course.name,
              courseFees: 0,
              courseDuration: "6 month",
              courseID: responseGetEnrollment.data.data.course_id,
              ISTDateString,
            };

            const printElement = await ReactDOMServer.renderToString(
              <Receipt data={dataForPdf} />
            );

            const opt = {
              margin: [5, 5, 5, 5],
              filename: `${responseGetEnrollment.data.data.surname}-${responseGetEnrollment.data.data.enrollment_no}`,
              html2canvas: { scale: 2 },
              jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            };

            await html2pdf().from(printElement).set(opt).save();

          }
        }
        redirect("/");
      } catch (error) {
        console.log(error);
      }
    }
    // =================================================================

    setValidated(true);
  };

  if (education.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Addmission Form"} curPage={"Sign Up"} />
      {/* start */}
      <div className="login-section section-bg">
        <div className="container">
          <div className="wrapper pt-3" style={{ textAlign: "left" }}>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="account-form"
            >
              {/* Full Name */}
              <h6>વ્યક્તિગત માહિતી</h6>
              <Row>
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    onKeyDown={(event) => {
                      const key = event.key;
                      const regex = /^[a-zA-Z]+$/; // Regular expression to match only alphabets

                      if (!regex.test(key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Surname"
                    value={data.surname}
                    onChange={handleChange(`surname`)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    onKeyDown={(event) => {
                      const key = event.key;
                      const regex = /^[a-zA-Z]+$/; // Regular expression to match only alphabets

                      if (!regex.test(key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Student Name"
                    value={data.student_name}
                    onChange={handleChange(`student_name`)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    onKeyDown={(event) => {
                      const key = event.key;
                      const regex = /^[a-zA-Z]+$/; // Regular expression to match only alphabets

                      if (!regex.test(key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Parents Name"
                    value={data.parents_name}
                    onChange={handleChange(`parents_name`)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

              </Row>
              {/* DOB */}
              <Row>
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    value={data.email}
                    onChange={handleChange(`email`)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  md="4"
                  as={Col}
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Date of birth"
                    onFocus={(e) => (e.currentTarget.type = "date")}
                    onBlur={(e) => (e.currentTarget.type = "text")}
                    value={data.birth_date}
                    onChange={handleChange(`birth_date`)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                {/* Gender */}
                <Form.Group
                  as={Col}
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Select
                    aria-label="Default select example"
                    required
                    placeholder="select gender..."
                    value={data.gender}
                    onChange={handleChange(`gender`)}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                {/* Gender */}

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Student's Mobile Number"
                    value={data.student_phone}
                    onChange={handleChange(`student_phone`)}
                    minLength={10}
                    maxLength={10}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Student's Whatsapp Number"
                    value={data.student_whatsapp_no}
                    onChange={handleChange(`student_whatsapp_no`)}
                    minLength={10}
                    maxLength={10}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                >
                  <Form.Control
                    type="text"
                    aria-label="aadhar"
                    placeholder="Student Aadhar number (XXXX XXXX XXXX XXXX)"
                    value={aadhar}
                    onChange={handleAadhaarNumber}
                    minLength={19}
                    maxLength={19}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Parent's Mobile Number"
                    value={data.parents_phone}
                    onChange={handleChange(`parents_phone`)}
                    minLength={10}
                    maxLength={10}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Parent's WHatsapp Number"
                    value={data.parent_whatsapp_no}
                    onChange={handleChange(`parent_whatsapp_no`)}
                    minLength={10}
                    maxLength={10}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Select
                    aria-label="Default select example"
                    required
                    placeholder="select relationship..."
                    value={data.relation_ship}
                    onChange={handleChange(`relation_ship`)}
                  >
                    <option value="">Relationship </option>
                    <option value="father"> Father(પિતા)</option>
                    <option value="wife"> Wife(પત્ની)</option>
                    <option value="brother">  Brother(ભાઈ)</option>
                    <option value="uncle">  Uncle(કાકા)</option>
                    <option value="mama">  Mama (મામા)</option>
                    <option value="other"> other(અન્ય)</option>
                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "3vh" }}
                >
                  <Form.Select
                    aria-label="Default select example"
                    required
                    placeholder="select relationship..."
                    value={data.parivart_student}
                    onChange={handleChange(`parivart_student`)}
                  >
                    <option value="">Parivartan Student </option>
                    <option value="new"> New</option>
                    <option value="old"> Old</option>
                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                {data.parivart_student == "old" && (
                  <Form.Group
                    as={Col}
                    md="4"
                    style={{ paddingBottom: "1vh" }}
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Enrollment Number"
                      value={data.enrollment_no}
                      onChange={handleChange(`enrollment_no`)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                )}
                {data.relation_ship == "other" && (
                  <Form.Group
                    as={Col}
                    md="4"
                    style={{ paddingBottom: "1vh" }}
                  >
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Relationship Name"
                      value={data.other_relation_ship}
                      onChange={handleChange(`other_relation_ship`)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                )}
              </Row>
              {/* Address */}
              <Row>
                <h6>સરનામુ</h6>

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Address Line-1"
                    value={data.current_address_line1}
                    onChange={handleChange(`current_address_line1`)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Address Line-2"
                    value={data.current_address_line2}
                    onChange={handleChange(`current_address_line2`)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    type="text"
                    minLength={6}
                    maxLength={6}
                    placeholder="PIN code"
                    value={data.current_picode}
                    onChange={handleChange(`current_picode`)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Select
                    aria-label="Default select example"
                    required
                    placeholder="select current_village..."
                    value={data.current_village}
                    onChange={handleChange(`current_village`)}
                  >
                    {pinDataOfAddress.length === 0 ? (
                      <option value="">Select City/Village</option>
                    ) : (
                      pinDataOfAddress[0].map((i) => (
                        <option
                          key={i.Name}
                          value={i.Name === "Select City/Village" ? "" : i.Name}
                        >
                          {i.Name}
                        </option>
                      ))
                    )}
                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    readOnly
                    required
                    type="text"
                    placeholder={
                      data.current_taluka === "" ? "Taluka" : data.current_taluka
                    }
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    readOnly
                    required
                    type="text"
                    placeholder={
                      data.current_district === ""
                        ? "District"
                        : data.current_district
                    }
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    readOnly
                    required
                    type="text"
                    placeholder={
                      data.current_state === "" ? "State" : data.current_state
                    }
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              {/* Permanent address */}
              <Row>
                <h6 className="col-md-4" style={{ maxWidth: "145px" }}>
                  કાયમી સરનામુ
                </h6>

                <Form.Group className="col-md-4">
                  <Form.Check.Input
                    type="checkbox"
                    id="same-as-above-checkbox"
                    onChange={handleChangeSameAsAbove}
                    style={{ marginTop: 0 }}
                  ></Form.Check.Input>
                  <Form.Label
                    style={{ paddingLeft: "1vw" }}
                    htmlFor="same-as-above-checkbox"
                  >
                    Same as above
                  </Form.Label>
                </Form.Group>
              </Row>

              <Row>
                {!showPermanentAddress ? (
                  <>
                    <Form.Group
                      as={Col}
                      md="4"
                      style={{ paddingBottom: "1vh" }}
                    >
                      <Form.Control
                        required
                        readOnly
                        type="text"
                        placeholder={
                          data.permanent_address_line1 === ""
                            ? "Address Line-1"
                            : data.permanent_address_line1
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      style={{ paddingBottom: "1vh" }}
                    >
                      <Form.Control
                        required
                        readOnly
                        type="text"
                        placeholder={
                          data.permanent_address_line2 === ""
                            ? "Address Line-2"
                            : data.permanent_address_line2
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      style={{ paddingBottom: "1vh" }}
                    >
                      <Form.Control
                        required
                        readOnly
                        type="text"
                        placeholder={
                          data.permanent_pincode === ""
                            ? "PIN code"
                            : data.permanent_pincode
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group
                      as={Col}
                      md="4"
                      style={{ paddingBottom: "1vh" }}
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Address Line-1"
                        value={data.permanent_address_line1}
                        onChange={handleChange(`permanent_address_line1`)}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="4"
                      style={{ paddingBottom: "1vh" }}
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Address Line-2"
                        value={data.permanent_address_line2}
                        onChange={handleChange(`permanent_address_line2`)}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="4"
                      style={{ paddingBottom: "1vh" }}
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="PIN code"
                        value={data.permanent_pincode}
                        onChange={handleChange(`permanent_pincode`)}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
              </Row>
              <Row>
                {!showPermanentAddress ? (
                  <Form.Group
                    as={Col}
                    md="4"
                    style={{ paddingBottom: "1vh" }}
                  >
                    <Form.Control
                      required
                      readOnly
                      type="text"
                      placeholder={
                        data.permanent_village === ""
                          ? "City"
                          : data.permanent_village
                      }
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <Form.Group
                    as={Col}
                    style={{ paddingBottom: "1vh" }}
                  >
                    <Form.Select
                      aria-label="Default select example"
                      required
                      value={data.permanent_village}
                      onChange={handleChange(`permanent_village`)}
                    >
                      {pinDataOfPermanentAddress.length === 0 ? (
                        <option value="">Select City/Village</option>
                      ) : (
                        pinDataOfPermanentAddress[0].map((i) => (
                          <option key={i.Name}>{i.Name}</option>
                        ))
                      )}
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                )}

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    readOnly
                    type="text"
                    placeholder={
                      data.permanent_taluka === ""
                        ? "Taluka"
                        : data.permanent_taluka
                    }
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    readOnly
                    type="text"
                    placeholder={
                      data.permanent_district === ""
                        ? "District"
                        : data.permanent_district
                    }
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="4"
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Control
                    required
                    readOnly
                    type="text"
                    placeholder={
                      data.permanent_state === ""
                        ? "State"
                        : data.permanent_state
                    }
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              {/* Education */}
              <Row>
                <h6>શિક્ષણ</h6>

                <Form.Group
                  as={Col}
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Select
                    aria-label="Default select example"
                    required
                    placeholder="select education_id..."
                    value={data.education_id}
                    onChange={handleChange(`education_id`)}
                  >
                    <option value="">Select highest Education</option>
                    {education.map((edu) => {
                      return (
                        <option value={edu.id} key={edu.id}>
                          {edu.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  md="12"
                  style={{ paddingBottom: "1vh" }}
                >
                  <table style={{ border: "1px", width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ border: "1px solid #d7d7d7", paddingRight: "1vh", padding: "5px" }}>12th (H.S.C)</td>
                        <td style={{ border: "1px solid #d7d7d7", paddingRight: "1vh", padding: "5px" }} colSpan="2">
                          <Form.Group>
                            < Form.Control
                              type="text"
                              placeholder={
                                data.twelfth_percentage === ""
                                  ? "%"
                                  : data.twelfth_percentage
                              }
                              onChange={handleChange(`twelfth_percentage`)}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid #d7d7d7", paddingRight: "1vh", padding: "5px" }}>Graduation</td>
                        <td style={{ border: "1px solid #d7d7d7", paddingRight: "1vh", padding: "5px" }} colSpan="2">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder={
                                data.graduate_percentage === ""
                                  ? "%"
                                  : data.graduate_percentage
                              }
                              onChange={handleChange(`graduate_percentage`)}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid #d7d7d7", paddingRight: "1vh", padding: "5px" }}>Other (અન્ય)</td>
                        <td style={{ border: "1px solid #d7d7d7", paddingRight: "1vh", padding: "5px" }}>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder={
                                data.other_study === ""
                                  ? "Education Name"
                                  : data.other_study
                              }
                              onChange={handleChange(`other_study`)}
                            />
                          </Form.Group>
                        </td>
                        <td style={{ border: "1px solid #d7d7d7", paddingRight: "1vh", padding: "5px" }}>
                          <Form.Control
                            type="text"
                            placeholder={
                              data.other_study_percentage === ""
                                ? "%"
                                : data.other_study_percentage
                            }
                            onChange={handleChange(`other_study_percentage`)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Form.Group>
              </Row>
              <Row className="mb-3" style={{ marginTop: "1rem" }}>
                <h6>શેમાં એડમિશન લેવા માંગો છો?</h6>

                <Form.Group
                  as={Col}
                  style={{ paddingBottom: "1vh" }}
                >
                  <Form.Select
                    aria-label="Default select example"
                    required
                    placeholder="select a categogy..."
                    value={data.category}
                    onChange={handleChange(`course_id`)}
                  >
                    {courseData.length === 0 ? (
                      <option value="">No courses are available</option>
                    ) : (
                      courseData.map((i) => (
                        <option key={i.id} value={i.id}>
                          {i.name}
                        </option>
                      ))
                    )}
                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md="8" style={{ paddingBottom: "8vh" }}>
                  <Form.Label
                    htmlFor="studentPassportImage"
                    style={{
                      fontWeight: "800",
                      fontSize: "2.5vh",
                      lineHeight: "3.5vh",
                    }}
                  >
                    પાસપોર્ટ સાઈઝનો ફોટો (1 MB થી ઓછી) (JPEG or PNG Format)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    id="studentPassportImage"
                    accept="image/jpeg, image/png"
                    onChange={handleImageChange(`studentPassportImage`)}
                    required
                  />

                  {imagesError.studentPassportImage && (
                    <Alert
                      variant="danger"
                      style={{ padding: "1vh", marginTop: "1vh" }}
                    >
                      {imagesError.studentPassportImage}
                    </Alert>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" style={{ paddingBottom: "1vh" }}>
                  <div style={{ width: "150px", height: "150px", border: "dotted", padding: "5px" }}>
                    {imagesPreview.studentPassportImage && (
                      <img
                        src={imagesPreview.studentPassportImage}
                        alt="studentPassportImage"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    )}
                  </div>
                </Form.Group>
              </Row>
              <Row>

                <Form.Group as={Col} md="8" style={{ paddingBottom: "10vh" }}>
                  <Form.Label
                    htmlFor="casteImage"
                    style={{
                      fontWeight: "800",
                      fontSize: "2.5vh",
                      lineHeight: "3.5vh",
                    }}
                  >
                    જાતિનો દાખલો (3 MB થી ઓછી) (JPEG or PNG Format)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    id="casteImage"
                    required
                    accept="image/jpeg, image/png"
                    onChange={handleImageChange(`casteImage`)}
                  />

                  {imagesError.casteImage && (
                    <Alert
                      variant="danger"
                      style={{ padding: "1vh", marginTop: "1vh" }}
                    >
                      {imagesError.casteImage}
                    </Alert>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <div style={{ width: "150px", height: "200px", border: "dotted", padding: "5px" }}>
                    {imagesPreview.casteImage && (

                      <img
                        src={imagesPreview.casteImage}
                        alt="casteImage"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />

                    )}
                  </div>
                </Form.Group>
              </Row>

              <div
                className="col"
                style={{
                  paddingBottom: "1vh",
                  fontWeight: "800",
                  fontSize: "2.5vh",
                  lineHeight: "3.5vh",
                }}
              >
                <h6 style={{ textAlign: "center", fontSize: "3vh", marginTop: "20px", marginBottom: "20px" }}>---- Rules & Regulationss ----</h6>
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  1. તાલીમ વર્ગો નિ:શુલ્ક રહેશે.
                </p>
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  2. નિયત ક્લાસની રજીસ્ટ્રેશન ફી ભરવાની રહેશે જે પરત મળવા પાત્ર  નથી.
                </p>
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  3. મોબાઈલ સ્વીચ ઓફ રાખવાનો રહેશે અન્યથા નિયત કરેલ દંડ ભરવાનો રહેશે..
                </p>
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  4. વર્ગમાં ગેરહાજર રહેનારને નિયત કરેલ દંડ ભરવાનો રહેશે..
                </p>
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  5. આઈકાર્ડ ફરજીયાત પહેરવાનું રહેશે.
                  રહશે.
                </p>
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  6. વર્ગ શરૂ થયાબાદ રજૂ થયેલા શિસ્તના તમામ નિયમોનું ફરજીયાત પાલન કરવાનું રહેશે.
                </p>
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  7. જે બહેનોએ રૂમ રાખીને રહેવાનું છે, તેઓએ ફરજીયાત ક્લાસમાં આવતા બહેનો સાથે જ રહેવાનું રહેશે.
                </p>
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  8. જે બહેનોએ રૂમ રાખીને રહેવાનું છે, તેઓએ ફરજીયાત ક્લાસમાં આવતા બહેનો સાથે જ રહેવાનું રહેશે.
                </p>
              </div>
              {/* Checkbox*/}
              <Form.Group className="mb-3">
                <Form.Check.Input
                  type="checkbox"
                  id="terms-and-condition-checkbox"
                  isInvalid={!data.terms_and_condition}
                  checked={data.terms_and_condition}
                  onChange={handleChangeTC}
                ></Form.Check.Input>
                <Form.Label
                  htmlFor="terms-and-condition-checkbox"
                  style={{ paddingLeft: "1vw" }}
                >
                  Agree to terms and conditions
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  You must agree before submitting.
                </Form.Control.Feedback>
              </Form.Group>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "800",
                    fontSize: "2.5vh",
                    lineHeight: "3.5vh",
                  }}
                >
                  હું પરિવર્તન ચેરિટેબલ ટ્રસ્ટના તમામ નિયમોનું પાલન કરવા બંધાયેલો/બંધાયેલી છું.અન્યથા એડમિશન રદ થશે.જેની જવાબદારી મારી રહેશે.
                </p>
              </div>
              <div className="form-group">
                <button className="lab-btn" type="submit">
                  <span>SUBMIT</span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div >
      {/* end */}
      {/* Modal Window Starts */}
      <ModalWindow
        show={showAlert}
        setShow={(val) => setShowAlert(val)}
        // body={Object.entries(data)
        //   .filter(([key, value]) => value === "")
        //   .map(([key, value]) => (
        //     <div key={key}>{key}</div>
        //   ))}
        body={`Please fill all the required fields`}
        showFooter={false}
        title={"Message"}
      />
      {/* Modal Window End */}
      {/* Modal for Razorpay SDK Starts */}
      <ModalWindow
        show={showRazorpayAlert}
        setShow={(val) => setShowRazorpayAlert(val)}
        body={"Razorpay SDK failed to load. Are you online? "}
        showFooter={false}
        footerBody={``}
        title={"Message"}
      />
      {/* Modal for Razorpay SDK Ends */}
      {/* Modal for Payment Starts */}
      <ModalWindow
        show={showPaymentAlert}
        setShow={(val) => setShowPaymentAlert(val)}
        body={paymentAlertBody}
        showFooter={false}
        footerBody={``}
        title={"Message"}
      />
      {/* Modal for Payment Ends */}
      {/* Modal for printing Application Starts */}
      <ModalWindow
        show={showPrintAlert}
        setShow={(val) => setShowPrintAlert(val)}
        body={printAlertBody}
        showFooter={false}
        footerBody={``}
        title={"Message"}
      />
      <ModalWindow
        show={showSuccessModal}
        setShow={(val) => setShowSuccessModal(val)}
        body={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <span style={{ fontSize: "large", fontWeight: "600" }}>
              {`Form Submitted successfully.`}
            </span>
            <span style={{ fontSize: "large", fontWeight: "600" }}>
              {`The form is completely filled in, please contact us at the Parivartan Office.`}
            </span>
          </div>
        }
        showFooter={false}
        title={"Success"}
      />
      {/* Modal for printing Application Ends */}
      <Footer />
    </Fragment >
  );
};

export default AddmissionForm;
