import Rating from "../sidebar/rating";
import { useState } from "react";
import VideoPlayer from "../sidebar/video-player";

// const title = "Advanced Adobe Photoshop For Everyone";
// const desc =
//   "The most impressive is collection of share me online college courses";
// const author = "Rajib Raj";
// const reviewCount = "03 reviews";
// const videoLink =
//   "https://parivartan.phpicoder.com/admin/courses/video/20230607115617.mp4";

// const categoryList = [
//   {
//     link: "#",
//     text: "Adobe XD",
//     className: "course-cate",
//   },
//   {
//     link: "#",
//     text: "30% Off",
//     className: "course-offer",
//   },
// ];

const PageHeaderTwo = ({ courseData }) => {
  const [showVideo, setShowVideo] = useState(false);
  const handleImageClick = () => {
    setShowVideo(true);
  };
  // console.log(courseData);
  return (
    <div className="pageheader-section style-2">
      <div className="container">
        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
          <div className="col-lg-7 col-12">
            <div className="pageheader-thumb">
              {showVideo ? (
                <VideoPlayer videoUrl={courseData.intro_video} />
              ) : (
                <>
                  <img
                    src={courseData.image}
                    alt="rajibraj91"
                    className="w-100"
                  //   onClick={handleImageClick}
                  />
                  <button
                    className="video-button popup"
                    target="_blank"
                    onClick={handleImageClick}
                  >
                    <i className="icofont-ui-play"></i>
                  </button>
                </>
              )}
              {/* <img
                src="assets/images/pageheader/02.jpg"
                alt="rajibraj91"
                className="w-100"
              /> */}
              {/* <a
                href={videoLink}
                className="video-button popup"
                target="_blank"
                onClick={handleImageClick}
              > 
                <i className="icofont-ui-play"></i>
              </a>*/}
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="pageheader-content">
              {/* <div className="course-category">
                {categoryList.map((val, i) => (
                  <a href={val.link} className={val.className} key={i}>
                    {val.text}
                  </a>
                ))}
              </div> */}
              <h2 className="phs-title">{courseData.name}</h2>
              <p className="phs-desc">{courseData.description}</p>
              <div className="phs-thumb">
                {/* <img src="assets/images/pageheader/03.jpg" alt="rajibraj91" /> */}
                {/* <span>{author}</span> */}
                <div className="course-reiew">
                  <Rating
                    ratingData={courseData.star}
                    type={"course"}
                    typeId={courseData.id}
                  />
                  <span className="ratting-count">{courseData.id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeaderTwo;
