const title = "Course Categories";

const CourseSideCetagory = ({ categoryData }) => {
  return (
    <div className="course-side-cetagory">
      <div className="csc-title">
        <h5 className="mb-0">{title}</h5>
      </div>
      <div className="csc-content">
        <div className="csdc-lists">
          <ul className="lab-ul">
            {categoryData.map((cat) => (
              //   <li key={cat.id}>
              //     <div className="csdc-left">
              //       <a href={cat.link}>{cat.left}</a>
              //     </div>
              //     <div className="csdc-right">{cat.right}</div>
              //   </li>
              <li key={cat.id}>
                <div className="csdc-left" style={{ color: "#101115" }}>
                  {cat.name}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CourseSideCetagory;
