import React, { Fragment, useEffect, useState, useRef } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import axios from "axios";
import LoadingSpinner from "../component/sidebar/loading-spinner";

const fetchData = async (count, setData, callInProgressRef, callAPIRef) => {
  // console.log(`Call API for count: ${count}`);

  try {
    const response = await axios.get(
      `https://parivartan.phpicoder.com/api/aproved_admission_list?page=${count}`
    );
    if (count === 1) {
      if (response.data.data.length !== 0) {
        setData(response.data.data);
        callInProgressRef.current = false; // Set call in progress to false after successful API response
      }
      if (response.data.data.length === 0) {
        callAPIRef.current = false;
        callInProgressRef.current = false; // Set call in progress to false if the response data length is 0
      }
    }
    if (count !== 1) {
      if (response.data.data.length !== 0) {
        setData((prevData) => [...prevData, ...response.data.data]);
        callInProgressRef.current = false; // Set call in progress to false after successful API response
      }
      if (response.data.data.length === 0) {
        callAPIRef.current = false;
        callInProgressRef.current = false; // Set call in progress to false if the response data length is 0
      }
    }
  } catch (error) {
    console.log("Error fetching data:", error);
    callInProgressRef.current = false; // Set call in progress to false on API error
  }
};

const QualifiedStudentsPage = () => {
  const [data, setData] = useState([]);
  const callInProgressRef = useRef(false);
  const countRef = useRef(1);
  const callAPIRef = useRef(true);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.scrollY >
        document.body.clientHeight -
        document.getElementById("footer").getClientRects()[0].height -
        (window.innerHeight * 3) / 4 &&
        callAPIRef.current &&
        !callInProgressRef.current
      ) {
        countRef.current++;
        callInProgressRef.current = true;
        fetchData(countRef.current, setData, callInProgressRef, callAPIRef);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Call fetchData initially to populate the initial data
  useEffect(() => {
    fetchData(countRef.current, setData, callInProgressRef, callAPIRef);
  }, []);

  // Render the data on the page

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Qualified Students"} curPage={"instructor"} />
      <div className="instructor-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
              {data.map((dt) => (
                <div className="col" key={dt.id}>
                  <div className="instructor-item">
                    <div className="instructor-inner">
                      <div className="instructor-thumb">
                        <img
                          src={`${dt.photo}`}
                          alt={`${dt.first_name}`}
                          style={{
                            height: "200px",
                            width: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="instructor-content">
                        <h4>{`${dt.first_name} ${dt.last_name}`}</h4>

                        <p>{dt.post_description}</p>
                      </div>
                    </div>
                    <div className="instructor-footer">
                      <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                        <li>
                          <i className="icofont-book-alt"></i> {dt.course_id}
                        </li>
                        <li>
                          <i className="icofont-certificate-alt-1"></i>{" "}
                          {dt.education_id}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default QualifiedStudentsPage;
