import { Fragment } from "react";

const DonorsSingleTemplate = ({ data }) => {
  const dateArray = data.date.split("-");
  // console.log(dateArray);
  return (
    <Fragment>
      {data.type === "show" ? (
        <div
          style={{
            width: "100vw",
            position: "relative",
          }}
        >
          <img
            src={`/assets/images/donors/donors_template.jpg`}
            alt={"donors_template"}
            style={{ width: "100%", height: "auto" }}
          />
          <div
            style={{
              position: "absolute",
              top: "40.6vw",
              left: "36.5vw",
              backgroundColor: "transparent",
              width: "26.4vw",
              height: "30.4vw",
            }}
          >
            <img
              src={data.photo}
              alt={data.name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "2.4vw",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "50vw",
              left: "69.5vw",
              backgroundColor: "transparent",
              width: "22vw",
              height: "6vw",
              alignItems: "end",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{ fontWeight: "800", color: "#000", fontSize: "3.8vw" }}
            >
              ₹{data.donation}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "56.3vw",
              left: "69.5vw",
              backgroundColor: "transparent",
              width: "22vw",
              height: "6vw",
              alignItems: "end",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: "800", color: "#000", fontSize: "3vw" }}>
              {`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "73.3vw",
              left: "19.7vw",
              backgroundColor: "transparent",
              width: "53vw",
              height: "5.5vw",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "600",
                color: "#000",
                fontSize: "2.5vw",
              }}
            >
              {data.name}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "79.3vw",
              left: "21.4vw",
              backgroundColor: "transparent",
              width: "53vw",
              height: "5.5vw",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "600",
                color: "#000",
                fontSize: "2.5vw",
              }}
            >
              {data.donated_by}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "87.4vw",
              left: "20.4vw",
              backgroundColor: "transparent",
              width: "15vw",
              height: "4vw",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "600",
                color: "#000",
                fontSize: "2.5vw",
              }}
            >
              {data.village}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "87.4vw",
              left: "47.8vw",
              backgroundColor: "transparent",
              width: "12.5vw",
              height: "4vw",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "600",
                color: "#000",
                fontSize: "2.5vw",
              }}
            >
              {data.taluko}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "87.5vw",
              left: "71.3vw",
              backgroundColor: "transparent",
              width: "16vw",
              height: "4vw",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "600",
                color: "#000",
                fontSize: "2.5vw",
              }}
            >
              {data.district}
            </div>
          </div>
        </div>
      ) : data.type === "print" ? (
        <div
          style={{
            width: "200mm",
            position: "relative",
          }}
        >
          <img
            src={`/assets/images/donors/donors_template.jpg`}
            alt={"donors_template"}
            style={{ width: "100%", height: "auto" }}
          />
          <div
            style={{
              position: "absolute",
              top: "82mm",
              left: "73.4mm",
              backgroundColor: "transparent",
              width: "52mm",
              height: "59.5mm",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
            }}
          >
            <img
              src={data.photo}
              alt={data.name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "4mm",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "97.5mm",
              left: "134mm",
              backgroundColor: "transparent",
              width: "54mm",
              height: "15mm",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: "800", color: "#000", fontSize: "9mm" }}>
              ₹{data.donation}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "110mm",
              left: "134mm",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "54mm",
              height: "15mm",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: "800", color: "#000", fontSize: "6mm" }}>
              {`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "145.5mm",
              left: "38.5mm",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "138mm",
              height: "12mm",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "5mm",
              }}
            >
              {data.name}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "157.5mm",
              left: "41.8mm",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "131mm",
              height: "12mm",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "5mm",
              }}
            >
              {data.donated_by}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "170.8mm",
              left: "41.8mm",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "38mm",
              height: "12mm",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "5mm",
              }}
            >
              {data.village}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "170.8mm",
              left: "96.6mm",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "31mm",
              height: "12mm",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "5mm",
              }}
            >
              {data.taluko}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "171.2mm",
              left: "143.5mm",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "40mm",
              height: "12mm",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "5mm",
              }}
            >
              {data.district}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <img
            src={`/assets/images/donors/donors_template.jpg`}
            alt={"donors_template"}
            style={{ width: "100%", height: "auto" }}
          />
          <div
            style={{
              position: "absolute",
              top: "29.2%",
              left: "36.8%",
              backgroundColor: "transparent",
              width: "26%",
              height: "21.5%",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
            }}
          >
            <img
              src={data.photo}
              alt={data.name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "7%",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "35%",
              left: "66%",
              backgroundColor: "transparent",
              width: "30%",
              height: "5%",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: "800", color: "#000", fontSize: "90%" }}>
              ₹{data.donation}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "39.5%",
              left: "66%",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "30%",
              height: "5%",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: "800", color: "#000", fontSize: "80%" }}>
              {`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "53%",
              left: "19.8%",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "67%",
              height: "4%",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "80%",
              }}
            >
              {data.name}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "57.3%",
              left: "21.4%",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "66%",
              height: "4%",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "80%",
              }}
            >
              {data.donated_by}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "62%",
              left: "20.6%",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "20.5%",
              height: "4%",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "80%",
              }}
            >
              {data.village}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "62%",
              left: "47.8%",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "17%",
              height: "4%",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "80%",
              }}
            >
              {data.taluko}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "62.1%",
              left: "71.3%",
              // padding: "5px",
              backgroundColor: "transparent",
              width: "17.5%",
              height: "4%",
              // border: "black 0.5vh solid",
              // borderRadius: "5vh",
              alignItems: "end",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                fontFamily: "Noto Sans Gujarati",
                fontWeight: "800",
                color: "#000",
                fontSize: "80%",
              }}
            >
              {data.district}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DonorsSingleTemplate;
