import { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";
import axios from "axios";
import LoadingSpinner from "../sidebar/loading-spinner";
import { Button } from "react-bootstrap";

const title = "Our Courses";

const CourseData = [
  {
    imgUrl: "assets/images/course/16.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    cate: "English",
    title: "Basic English Spoken and Writing",
    author: "assets/images/course/author/01.jpg",
    authorName: "William Smith",
    price: "$199.00",
    id: 1,
  },
  {
    imgUrl: "assets/images/course/17.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    cate: "Software",
    title: "Learn Advance with PHP Language",
    author: "assets/images/course/author/02.jpg",
    authorName: "Angel Mili",
    price: "$199.00",
    id: 2,
  },
  {
    imgUrl: "assets/images/course/18.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    cate: "Design",
    title: "Logo Design in Adobe Illustrator",
    author: "assets/images/course/author/03.jpg",
    authorName: "Sajahan Sagor",
    price: "$199.00",
    id: 3,
  },
  {
    imgUrl: "assets/images/course/19.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    cate: "Photography",
    title: "Digital Photography for Beginner",
    author: "assets/images/course/author/04.jpg",
    authorName: "Ummi Nishat",
    price: "$199.00",
    id: 4,
  },
  {
    imgUrl: "assets/images/course/20.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    cate: "Marketing",
    title: "Advance Social Media Marketing",
    author: "assets/images/course/author/05.jpg",
    authorName: "Rassel Hossin",
    price: "$199.00",
    id: 5,
  },
  {
    imgUrl: "assets/images/course/21.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    cate: "Music",
    title: "A Guidelines Complete Guitar System",
    author: "assets/images/course/author/06.jpg",
    authorName: "Zinat Zaara",
    price: "$199.00",
    id: 6,
  },
  {
    imgUrl: "assets/images/course/22.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    cate: "Design",
    title: "Learn Piano Guidelines System A to Z",
    author: "assets/images/course/author/01.jpg",
    authorName: "William Smith",
    price: "$199.00",
    id: 7,
  },
  {
    imgUrl: "assets/images/course/23.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    cate: "Software",
    title: "Advance Professional Graphic Design",
    author: "assets/images/course/author/02.jpg",
    authorName: "Angel Mili",
    price: "$199.00",
    id: 8,
  },
];

const CourseFour = () => {
  const [items, setItems] = useState();
  const [courseData, setCourseData] = useState();
  const [categoryList, setCategoryList] = useState();

  const filterItem = (categItem) => {
    // const updateItems = courseData.filter((course) => {
    //   return course.category.map((cat) => cat.name === categItem);
    // });
    const updateItems = [];
    courseData.forEach((course) => {
      course.category.forEach((cat) => {
        if (cat.name === categItem) {
          updateItems.push(course);
        }
      });
    });
    setItems(updateItems);
  };
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get(
          "https://parivartan.phpicoder.com/api/get_course"
        );

        if (response.data.status) {
          setCourseData(response.data.data);
          setItems(response.data.data);
        }
        const responseCategory = await axios.get(
          "https://parivartan.phpicoder.com/api/get_course_categories"
        );

        if (responseCategory.data.status) {
          setCategoryList(responseCategory.data.data);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchCourseData();
  }, []);

  if (!courseData) {
    return <LoadingSpinner />;
  }
  if (!categoryList) {
    return <LoadingSpinner />;
  }
  if (!items) {
    return <LoadingSpinner />;
  }
  return (
    <div className="course-section style-3 padding-tb">
      <div className="course-shape one">
        <img src="assets/images/shape-img/icon/01.png" alt="education" />
      </div>
      <div className="course-shape two">
        <img src="assets/images/shape-img/icon/02.png" alt="education" />
      </div>
      <div className="container">
        <div className="section-header">
          <h2 className="title">{title}</h2>
          <div className="course-filter-group">
            <ul className="lab-ul">
              <li onClick={() => setItems(courseData)}>All</li>
              {categoryList.map((cat) => {
                return (
                  <li onClick={() => filterItem(cat.name)} key={cat.id}>
                    {cat.name}
                  </li>
                );
              })}
              {/* <li onClick={() => filterItem("English")}>English</li>
              <li onClick={() => filterItem("Software")}>Software</li>
              <li onClick={() => filterItem("Design")}>Design</li>
              <li onClick={() => filterItem("Photography")}>Photography</li>
              <li onClick={() => filterItem("Music")}>Music</li>
              <li onClick={() => filterItem("Marketing")}>Marketing</li> */}
            </ul>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 course-filter">
            {items.map((course) => {
              //   const {
              //     id,
              //     imgUrl,
              //     imgAlt,
              //     cate,
              //     title,
              //     author,
              //     authorName,
              //     price,
              //   } = elem;
              return (
                <div className="col" key={course.id}>
                  <div className="course-item style-4">
                    <div className="course-inner">
                      <div className="course-thumb">
                        <img
                          src={course.image}
                          alt={course.name}
                          style={{ height: "300px", objectFit: "contain" }}
                        />
                        {/* <div className="course-category">
                          {course.category.map((cat) => {
                            return (
                              <div className="course-cate" key={cat.id}>
                                <a href="#">{cat.name}</a>
                              </div>
                            );
                          })}
                          <div className="course-reiew">
                            <Rating ratingData={course.star} />
                          </div>
                        </div> */}
                      </div>
                      <div className="course-content">
                        <Button
                          as={Link}
                          to={{
                            pathname: "/course-single",
                            search: `course_id=${course.id}`,
                          }}
                          className="lab-btn-text"
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                        >
                          <h5>{course.name}</h5>
                        </Button>
                        {/* <Link
                          to={{ path: "/course-single", search: "course_id=1" }}
                        >
                          <h5>{course.name}</h5>
                        </Link> */}
                        {/* <div className="course-footer">
                          <div className="course-author">
                            {course.teacher.map((teach) => {
                              return (
                                <div
                                  key={teach.id}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <img
                                    src={teach.photo}
                                    alt={teach.name}
                                    style={{
                                      height: "100px",
                                      width: "100px",
                                      borderRadius: "50px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <Link
                                    to={{
                                      pathname: "/teacher-single",
                                      search: `teacher_id=${teach.id}`,
                                    }}
                                    className="ca-name"
                                  >
                                    {teach.name}
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                          <div className="course-price">{`₹${course.price}`}</div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseFour;
