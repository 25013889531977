import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory";
import CourseSideDetail from "../component/sidebar/course-detail";
import Respond from "../component/sidebar/respond";
import { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../component/sidebar/loading-spinner";

const CourseSingle = () => {
  const [data, setData] = useState();
  const [commentData, setCommentData] = useState();

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const id = searchParams.get("course_id");

    // console.log(id); // Outputs the value of 'id' parameter

    const courseIdForm = new FormData();
    courseIdForm.append("course_id", id);
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://parivartan.phpicoder.com/api/get_course_id",
          courseIdForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            mode: "no-cors",
          }
        );
        // console.log(response.data.data);
        if (response.data.status) {
          setData(response.data.data[0]);
        }
      } catch (error) {
        // Handle error
        console.log(error);
      }
    };

    fetchData();

    const fetchCommentData = async () => {
      try {
        const response = await axios.post(
          "https://parivartan.phpicoder.com/api/get_course_review_byCourseId",
          courseIdForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            mode: "no-cors",
          }
        );
        // console.log(response);
        if (!response.data.status) {
          setCommentData(response.data.data);
        }
      } catch (error) {
        // Handle error
        console.log(error);
      }
    };

    fetchCommentData();
  }, []);
  // console.log(data);
  // console.log(commentData);
  if (!data) {
    return <LoadingSpinner />;
  }

  if (data === undefined) {
    return (
      <>
        <div>No course Data</div>
      </>
    );
  }
  return (
    <Fragment>
      <Header />
      <PageHeaderTwo courseData={data} />
      <div className="course-single-section padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      {/* <h3>Course Overview</h3>
                      <p>
                        In this course take you from the fundamentals and
                        concepts of data modeling all the way through anumber of
                        best practices and techniques that you’ll need to build
                        data models in your organization. You’ll find many By
                        the end of the course, you’ll be all set to not only put
                        these principles to works but also to maike the key data
                        modeling and design decisions required by the info data
                        modeling that transcend the nuts-and-bolts that clearly
                        the key covered the course and design patterns.
                      </p>
                      <h4>What You'll Learn in This Course:</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>Ready to begin
                          working on real-world data modeling projects
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Expanded
                          responsibilities as part of an existing role
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Be able to create
                          Flyers, Brochures, Advertisements
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Find a new
                          position involving data modeling.
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Work with color
                          and Gradients and Grids
                        </li>
                      </ul>
                      <p>
                        In this course take you from the fundamentals and
                        concepts of data modeling all the way through anumber of
                        best practices and techniques that you’ll need to build
                        data models in your organization. You’ll find many
                        examples that clearly the key covered the course
                      </p>
                      <p>
                        By the end of the course, you’ll be all set to not only
                        put these principles to works but also to maike the key
                        data modeling and design decisions required by the info
                        data modeling that transcend the nuts-and-bolts that
                        clearly the key covered the course and design patterns.
                      </p> */}

                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.content,
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="course-video">
                  <div className="course-video-title">
                    <h4>Course Content</h4>
                  </div>
                  <div className="course-video-content">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <div className="accordion-header" id="accordion01">
                          <button
                            className="d-flex flex-wrap justify-content-between"
                            data-bs-toggle="collapse"
                            data-bs-target="#videolist1"
                            aria-expanded="true"
                            aria-controls="videolist1"
                          >
                            <span>1.Introduction</span>{" "}
                            <span>5lessons, 17:37</span>{" "}
                          </button>
                        </div>
                        <div
                          id="videolist1"
                          className="accordion-collapse collapse show"
                          aria-labelledby="accordion01"
                          data-bs-parent="#accordionExample"
                        >
                          <ul className="lab-ul video-item-list">
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.1 Welcome to the course 02:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.2 How to set up your photoshop workspace 08:33
                                minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.3 Essential Photoshop Tools 03:38 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.4 Finding inspiration 02:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                1.5 Choosing Your Format 03:48 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="accordion02">
                          <button
                            className="d-flex flex-wrap justify-content-between"
                            data-bs-toggle="collapse"
                            data-bs-target="#videolist2"
                            aria-expanded="true"
                            aria-controls="videolist2"
                          >
                            {" "}
                            <span>
                              2.How to Create Mixed Media Art in Adobe Photoshop
                            </span>{" "}
                            <span>5 lessons, 52:15</span>{" "}
                          </button>
                        </div>
                        <div
                          id="videolist2"
                          className="accordion-collapse collapse"
                          aria-labelledby="accordion02"
                          data-bs-parent="#accordionExample"
                        >
                          <ul className="lab-ul video-item-list">
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.1 Using Adjustment Layers 06:20 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.2 Building the composition 07:33 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.3 Photoshop Lighting effects 06:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.4 Digital Painting using photoshop brushes
                                08:34 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                            <li className=" d-flex flex-wrap justify-content-between">
                              <div className="video-item-title">
                                2.5 Finalizing the details 10:30 minutes
                              </div>
                              <div className="video-item-icon">
                                <a
                                  href="https://www.youtube.com/watch?v=UZstzHokS4U"
                                  className="popup"
                                  target="_blank"
                                >
                                  <i className="icofont-play-alt-2"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {data.teacher.map((teach) => {
                  return (
                    <div key={teach.id}>
                      <Author authorData={teach} />
                    </div>
                  );
                })}
                {/* {commentData === undefined ? (
                  <h4>Be the first one to comment</h4>
                ) : (
                  <Comment commentData={commentData} />
                )} */}
                {/* <Respond /> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                <CourseSideDetail courseData={data} />
                <CourseSideCetagory categoryData={data.category} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default CourseSingle;
