const Receipt = ({ data }) => {
  console.log(data);
  return (
    <div
      style={{
        width: "198mm",
        height: "257mm",
        border: "double",
        backgroundColor: "lightgray",
        margin: "5px",
      }}
    >
      <div
        style={{
          border: "double",
          display: "flex",
          margin: "5px",
          padding: "5px",
        }}
      >
        <div style={{ width: "100px" }}>
          <img src="assets/images/logo/01.png" alt="Invoice logo" />
        </div>
        <div
          style={{
            margin: "5px",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "40px" }}>
            Shree Parivartan Carrer Academy
          </p>
          <p
            style={{
              margin: "0px",
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "25px",
            }}
          >
            Surendranagar
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            80 Foot Roard, Patel Boding, Parivart Anacademy
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            +91 9979939391
          </p>
        </div>
      </div>
      <div
        style={{
          border: "double",
          display: "flex",
          margin: "5px",
          padding: "5px",
        }}
      >
        <div
          style={{
            width: "50%",
            margin: "5px",
            marginTop: "10px",
            textAlign: "left",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            RECEIPT NO. : {data.studentRollNo}
          </p>
          <p
            style={{
              margin: "0px",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          ></p>

          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            COURSE NAME : {data.courseName.toUpperCase()}
          </p>
        </div>
        <div
          style={{
            width: "50%",
            margin: "5px",
            marginTop: "10px",
            textAlign: "right",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            RECEIPT DATE : {data.ISTDateString}
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            COURSE DURATION : {data.courseDuration}
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            COURSE FEES : {`₹ ${data.courseFees} /-`}
          </p>
        </div>
      </div>
      <div
        style={{
          border: "double",
          display: "flex",
          margin: "5px",
          padding: "5px",
        }}
      >
        <div
          style={{
            width: "90%",
            margin: "5px",
            marginTop: "10px",
            textAlign: "left",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            NAME :
            {` ${data.studentFirstName.toUpperCase()} ${data.studentLastName.toUpperCase()}`}
          </p>
          <p
            style={{
              margin: "0px",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            Enrollment No. : {data.studentRollNo}
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            EMAIL : {data.studentEmail}
          </p>
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "15px" }}>
            MOBILE : {data.studentMobile}
          </p>
        </div>

        <div
          style={{
            border: "solid",
          }}
        >
          <img
            src={data.studentPhoto}
            alt={data.studentFirstName}
            width={"100px"}
            style={{ backgroundColor: "transparent" }}
          />
        </div>
      </div>
      {/* Student Profile END */}

      {/* Payment Details START */}
      <div
        style={{
          border: "double",
          display: "flex",
          margin: "5px",
          padding: "5px",
        }}
      >
        {/* <div style={{ width: "100%", margin: "0px", marginBottom: "-15px" }}>
          <table className="table table-bordered border-secondary">
            <thead>
              <tr>
                <th className="per70 text-center">Description</th>
                <th className="per5 text-center">Qty</th>
                <th className="per25 text-center">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  1024MB Cloud 2.0 Server - elisium.dynamic.com (12/04/2014 -
                  01/03/2015)
                </td>
                <td className="text-center">1</td>
                <td className="text-center">$25.00 USD</td>
              </tr>
              <tr>
                <td>Logo design</td>
                <td className="text-center">1</td>
                <td className="text-center">$200.00 USD</td>
              </tr>
              <tr>
                <td>Backup - 1024MB Cloud 2.0 Server - elisium.dynamic.com</td>
                <td className="text-center">12</td>
                <td className="text-center">$12.00 USD</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={2} className="text-right">
                  Sub Total:
                </th>
                <th className="text-center">$237.00 USD</th>
              </tr>
              <tr>
                <th colSpan={2} className="text-right">
                  20% VAT:
                </th>
                <th className="text-center">$47.40 USD</th>
              </tr>
              <tr>
                <th colSpan={2} className="text-right">
                  Credit:
                </th>
                <th className="text-center">$00.00 USD</th>
              </tr>
              <tr>
                <th colSpan={2} className="text-right">
                  Total:
                </th>
                <th className="text-center">$284.4.40 USD</th>
              </tr>
            </tfoot>
          </table>
        </div> */}
      </div>
      {/* Payment Details END*/}

      {/* Message START */}
      <div
        style={{
          border: "double",
          margin: "5px",
          padding: "5px",
          display: "flex",
          marginTop: "167px",
        }}
      >
        <div
          style={{
            margin: "5px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <p style={{ margin: "0px", fontWeight: "bold", fontSize: "20px" }}>
            This is a Computer generated receipt and does not require any
            Signature.
          </p>
        </div>
      </div>
      {/* Message END */}
    </div>
  );
};

export default Receipt;
