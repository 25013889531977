import { Component, Fragment, useEffect, useState } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";
import axios from "axios";
import LoadingSpinner from "../component/sidebar/loading-spinner";
import ModalWindow from "../component/modalWindow.jsx";

const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with us";
const conTitle =
  "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Send your Message";

// const contactList = [
//   {
//     imgUrl: "assets/images/icon/01.png",
//     imgAlt: "contact icon",
//     title: "Office Address",
//     desc: "80 Foot Roard, Patel Boding",
//   },
//   {
//     imgUrl: "assets/images/icon/02.png",
//     imgAlt: "contact icon",
//     title: "Phone number",
//     desc: "+91 9898182091",
//   },
//   {
//     imgUrl: "assets/images/icon/03.png",
//     imgAlt: "contact icon",
//     title: "Send email",
//     desc: "info@parivartanacademy.org.in",
//   },
//   {
//     imgUrl: "assets/images/icon/04.png",
//     imgAlt: "contact icon",
//     title: "Our website",
//     desc: "www.parivartanacademy.org.in",
//   },
// ];

const ContactPage = () => {
  const [conactData, setContactData] = useState();
  const [messageData, setMessageData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFaliureModal, setShowFaliureModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const responseGetSetting = await axios.get(
        "https://parivartan.phpicoder.com/api/get_setting"
      );
      if (responseGetSetting.data.status) {
        setContactData(responseGetSetting.data.data);
      }
    };
    fetchData();
  }, []);

  const handleChange = (props) => (e) => {
    e.preventDefault();
    setMessageData({ ...messageData, [props]: e.target.value });
  };

  const handleSendMessage = async () => {
    console.log(messageData);

    const addContact = new FormData();
    addContact.append("name", messageData.name);
    addContact.append("email", messageData.email);
    addContact.append("mobile", messageData.mobile);
    addContact.append("subject", messageData.subject);
    addContact.append("message", messageData.message);

    const responseAddContact = await axios.post(
      "https://parivartan.phpicoder.com/api/add_contact",
      addContact
    );
    if (responseAddContact.data.status) {
      setShowSuccessModal(true);
      setMessageData({
        name: "",
        email: "",
        mobile: "",
        subject: "",
        message: "",
      });
    }
    if (!responseAddContact.data.status) {
      setShowFaliureModal(true);
    }
  };

  if (!conactData) {
    return <LoadingSpinner />;
  }
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Get In Touch With Us"} curPage={"Contact Us"} />
      <div className="map-address-section padding-tb section-bg">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{subTitle}</span>
            <h2 className="title">{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-xl-4 col-lg-5 col-12">
                <div className="contact-wrapper">
                  {/* {contactList.map((val, i) => ( */}
                  <div className="contact-item">
                    <div className="contact-thumb">
                      <img src="assets/images/icon/01.png" alt="contact icon" />
                    </div>
                    <div className="contact-content">
                      <h6 className="title">{"Office Address"}</h6>
                      <p>{conactData.address}</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <div className="contact-thumb">
                      <img src="assets/images/icon/02.png" alt="contact icon" />
                    </div>
                    <div className="contact-content">
                      <h6 className="title">{"Phone number"}</h6>
                      <p>{conactData.mobile}</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <div className="contact-thumb">
                      <img src="assets/images/icon/03.png" alt="contact icon" />
                    </div>
                    <div className="contact-content">
                      <h6 className="title">{"Send email"}</h6>
                      <p>{conactData.email}</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <div className="contact-thumb">
                      <img src="assets/images/icon/04.png" alt="contact icon" />
                    </div>
                    <div className="contact-content">
                      <h6 className="title">{"Our website"}</h6>
                      <p>{conactData.website}</p>
                    </div>
                  </div>
                  {/* ))} */}
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-12">
                <GoogleMap />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            {/* <form className="contact-form"> */}
            <div className="contact-form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name *"
                  value={messageData.name}
                  onChange={handleChange("name")}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  placeholder="Your Email *"
                  value={messageData.email}
                  onChange={handleChange("email")}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="number"
                  placeholder="Mobile Number *"
                  value={messageData.mobile}
                  onChange={handleChange("mobile")}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  placeholder="Your Subject *"
                  value={messageData.subject}
                  onChange={handleChange("subject")}
                />
              </div>
              <div className="form-group w-100">
                <textarea
                  rows="8"
                  type="text"
                  placeholder="Your Message"
                  value={messageData.message}
                  onChange={handleChange("message")}
                ></textarea>
              </div>
              <div className="form-group w-100 text-center">
                <button className="lab-btn" onClick={handleSendMessage}>
                  <span>{btnText}</span>
                </button>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      <ModalWindow
        show={showFaliureModal}
        setShow={(val) => setShowSuccessModal(val)}
        body={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <span style={{ fontSize: "large", fontWeight: "600" }}>
              {`Thank you for providing your feedback.`}
            </span>
            <span style={{ fontSize: "large", fontWeight: "600" }}>
              {`We are working on your request and will get back to you soon.`}
            </span>
          </div>
        }
        showFooter={false}
        title={"Success"}
      />
      <ModalWindow
        show={showFaliureModal}
        setShow={(val) => setShowFaliureModal(val)}
        body={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <span style={{ fontSize: "large", fontWeight: "600" }}>
              {`Error sending message! Please try again.`}
            </span>
          </div>
        }
        showFooter={false}
        title={"Faliure"}
      />
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
