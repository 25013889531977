import Spinner from "react-bootstrap/Spinner";

function LoadingSpinner() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spinner
        animation="grow"
        style={{
          color: "#f16126",
          minWidth: "5vh",
          minHeight: "5vh",
        }}
      />
    </div>
  );
}

export default LoadingSpinner;
