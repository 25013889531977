// import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalWindow({ show, setShow, body, showFooter, footerBody, title }) {
  // const [show, setShow] = useState(false);
  // console.log(body);
  const handleClose = () => {
    return setShow(!show);
  };
  // const handleShow = () => {
  //   return setShow(true);
  // };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#F16126", color: "white" }}
        >
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        {showFooter && (
          <Modal.Footer
          // style={{ backgroundColor: "#1f1f33" }}
          >
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Understood</Button> */}
            {footerBody}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalWindow;

/*
  const [showModalWindow, setShowModalWindow] = useState(false);
  const [modalBody,setModalBody] = useState('')


<ModalWindow
  show={showModalWindow}
  setShow={(val) => setShowModalWindow(val)}
  body={``}
  showFooter={false}
  footerBody={``}
  title={"Message"}
/>;
*/
