import { Fragment } from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingSpinner from "../component/sidebar/loading-spinner";
import DonorsSingleTemplate from "./donors-single_template";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

const DonorsSingle = () => {
  const [dataShreeData, setDataShreeData] = useState();
  // const [openShare, setOpenShare] = useState(false);
  // const [transformValue, setTransFormValue] = useState("");

  useEffect(() => {
    const url = new URL(window.location.href);
    const urlArray = url.href.split("/");
    const id = urlArray[urlArray.length - 1];

    const dataShreeIdForm = new FormData();
    dataShreeIdForm.append("id", id);
    try {
      const fetchData = async () => {
        const response = await axios.post(
          `https://parivartan.phpicoder.com/api/find_data_shree`,
          dataShreeIdForm
        );
        if (response.data.status) {
          setDataShreeData(response.data.data);
        }
      };
      fetchData();
    } catch (error) {
      console.log(error);
    }

    // // Function to remove the scrollbar by setting the overflow property of the body to "hidden"
    // const removeScrollbar = () => {
    //   document.body.style.overflow = 'hidden';
    // };

    // // Call the function to remove the scrollbar when the component mounts
    // removeScrollbar();

    // // Cleanup function to reset the overflow property when the component unmounts
    // return () => {
    //   document.body.style.overflow = 'auto';
    // };
  }, []);

  if (!dataShreeData) {
    return <LoadingSpinner />;
  }

  const handlePrint = async () => {
    console.log(`printing.....`);

    // ------------------------(html2pdf.js)--------------------------------------
    const printElement = await ReactDOMServer.renderToString(
      <DonorsSingleTemplate data={{ ...dataShreeData, type: "print" }} />
    );
    const option = {
      margin: [9, 5.5, 5, 5],
      filename: `${dataShreeData.name}-${dataShreeData.donation}-${dataShreeData.date}`,
      image: { type: "jpeg", quality: 0.2 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    // console.log(printElement);
    await html2pdf().set(option).from(printElement).save();
    // --------------------------------------------------------------

    console.log(`printed!`);
  };

  // const handleClickShare = () => {
  //   if (openShare) {
  //     setTransFormValue("");
  //   }
  //   if (!openShare) {
  //     setTransFormValue("translateX(212px)");
  //   }
  //   setOpenShare(!openShare);
  // };

  return (
    <>
      {/* <div
        style={{
          position: "fixed",
          width: "340px",
          height: "60px",
          top: "40vh",
          left: "-212px",
          backgroundColor: "#393186",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",         
          borderRadius: "0px 10px 10px 0px",
          boxShadow: "rgb(241, 97, 38) 0px 0px 20px",
          transition:
            "transform 0.3s ease" ,
          transform: `${transformValue}` ,
        }}
      > */}
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            position: "fixed",
            width: "55px",
            height: "270px",
            // top: "40vh",
            // left: "0px",
            backgroundColor: "#393186",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0px 10px 10px 0px",
            boxShadow: "rgb(241, 97, 38) 0px 0px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "10px",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <FacebookShareButton
                url={`${window.location.origin}/donor/${dataShreeData.id}`}
                quote={`I have donated ₹ ${dataShreeData.donation} for Parivartan Academy. Make your contribution and help the academy grow.`}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={`${window.location.origin}/donor/${dataShreeData.id}`}
                title={`I have donated ₹ ${dataShreeData.donation} for Parivartan Academy.Make your contribution and help the academy grow.`}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton
                url={`${window.location.origin}/donor/${dataShreeData.id}`}
                title={`I have donated ₹ ${dataShreeData.donation} for Parivartan Academy.Make your contribution and help the academy grow.`}
                separator=":: "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={`${window.location.origin}/donor/${dataShreeData.id}`}
                className="Demo__some-network__share-button"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <EmailShareButton
                url={`${window.location.origin}/donor/${dataShreeData.id}`}
                subject={`I have donated ₹ ${dataShreeData.donation} for Parivartan Academy.Make your contribution and help the academy grow.`}
                body="body"
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: "0px",
                  font: "inherit",
                  color: "inherit",
                  cursor: "pointer",
                  width: "32px",
                  height: "32px",
                  top: "0px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    backgroundColor: "#e4405f",
                  }}
                >
                  <i
                    className="icofont-instagram"
                    style={{
                      color: "white",
                      top: "4px",
                      position: "relative",
                    }}
                  ></i>
                </div>
              </button>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: "0px",
                  font: "inherit",
                  color: "inherit",
                  cursor: "pointer",
                  width: "32px",
                  height: "32px",
                  top: "0px",
                  position: "relative",
                }}
                onClick={handlePrint}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    backgroundColor: "green",
                    marginTop: "2px",
                  }}
                >
                  <i
                    className="icofont-printer"
                    style={{
                      color: "white",
                      top: "4px",
                      position: "relative",
                    }}
                  ></i>
                </div>
              </button>
            </div>
          </div>
          {/* <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="form-group"
            // style={{ marginTop: "30px" }}
          >
            <button
              className="lab-btn"
              onClick={handleClickShare}
              // style={{ fontSize: "75%" }}
            >
              <span>SHARE</span>
            </button>
          </div>
        </div> */}
        </div>
      </div>
      <DonorsSingleTemplate data={{ ...dataShreeData, type: "show" }} />
    </>
  );
};

export default DonorsSingle;
