import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import ModalWindow from "../modalWindow";

const Rating = ({ ratingData, type, typeId }) => {
  //======INITIAL CODE===============================================================
  // const ratings = [];
  // for (let i = 1; i <= 5; i++) {
  //   if (i <= ratingData) {
  //     ratings.push(1);
  //     continue;
  //   }
  //   if (ratingData <= i && i - ratingData >= 0.5 && i - ratingData < 1) {
  //     ratings.push(0.5);
  //     continue;
  //   }
  //   if (ratingData <= i && i - ratingData <= 0.5 && i - ratingData >= 0) {
  //     ratings.push(1);
  //     continue;
  //   }
  //   if (ratingData <= i) {
  //     ratings.push(0);
  //     continue;
  //   }
  // }

  // if (!ratingData) {
  //   return (
  //     <span className="ratting">
  //       <FontAwesomeIcon
  //         icon={faStarRegular}
  //         style={{ color: "black" }}
  //         // size="lg"
  //       />
  //       <FontAwesomeIcon
  //         icon={faStarRegular}
  //         style={{ color: "black" }}
  //         // size="lg"
  //       />
  //       <FontAwesomeIcon
  //         icon={faStarRegular}
  //         style={{ color: "black" }}
  //         // size="lg"
  //       />
  //       <FontAwesomeIcon
  //         icon={faStarRegular}
  //         style={{ color: "black" }}
  //         // size="lg"
  //       />
  //       <FontAwesomeIcon
  //         icon={faStarRegular}
  //         style={{ color: "black" }}
  //         // size="lg"
  //       />
  //     </span>
  //   );
  // }

  // return (
  //   <span className="ratting">
  //     {ratings.map((rat, i) => {
  //       return rat === 1 ? (
  //         <FontAwesomeIcon
  //           icon={faStarSolid}
  //           style={{ color: "#F16126" }}
  //           // size="lg"
  //           key={i}
  //         />
  //       ) : rat === 0.5 ? (
  //         <FontAwesomeIcon
  //           icon={faStarHalfStroke}
  //           style={{ color: "#F16126" }}
  //           key={i}
  //           // size="lg"
  //         />
  //       ) : (
  //         <FontAwesomeIcon
  //           icon={faStarRegular}
  //           style={{ color: "black" }}
  //           key={i}
  //           // size="lg"
  //         />
  //       );
  //     })}
  //   </span>
  // );
  //======INITIAL CODE==============================================================

  const [rating, setRating] = useState(2); //average of rating given by people
  const [ratingBy, setRatingBy] = useState(1); //rating given how many people
  const [color, setColor] = useState({
    1: "black",
    2: "black",
    3: "black",
    4: "black",
    5: "black",
  });
  const [icon, setIcon] = useState({
    1: faStarRegular,
    2: faStarRegular,
    3: faStarRegular,
    4: faStarRegular,
    5: faStarRegular,
  });
  const [showModalWindow, setShowModalWindow] = useState(false);
  const [newRating, setNewRating] = useState(0); //new rating given by the user through modal window

  const handleClickOpenModal = (e) => {
    e.preventDefault();
    setShowModalWindow(true);
  };

  const handleClickSetStar = (e) => {
    e.preventDefault();
    const starId = parseInt(
      e.target.id ? e.target.id : e.target.parentElement.id
    );

    if (starId) {
      // Update stars up to the clicked star
      for (let i = 1; i <= starId; i++) {
        setColor((prevColor) => ({ ...prevColor, [i]: "#F16126" }));
        setIcon((prevIcon) => ({ ...prevIcon, [i]: faStarSolid }));
      }

      // Update stars after the clicked star
      for (let i = starId + 1; i <= 5; i++) {
        setColor((prevColor) => ({ ...prevColor, [i]: "black" }));
        setIcon((prevIcon) => ({ ...prevIcon, [i]: faStarRegular }));
      }
    }
    setNewRating(starId);
  };

  const handleClickConfirm = (e) => {
    e.preventDefault();
    const total_rating = rating * ratingBy + newRating;
    setRating(total_rating / (ratingBy + 1));
    setRatingBy(ratingBy + 1);
    setShowModalWindow(false);
    console.log(`****Rating for ${type}_${typeId}****`);
    console.log(`Old Total: ${rating * ratingBy}`);
    console.log(`By: ${ratingBy} people`);
    console.log(`Rating: ${rating} `);
    console.log(`==Rating by user: ${newRating}==`);
    console.log(`New Total: ${total_rating}`);
    console.log(`By: ${ratingBy + 1} people`);
    console.log(`Rating: ${total_rating / (ratingBy + 1)} `);
    console.log(`*******************`);
    localStorage.setItem(`${type}_${typeId}`, "yes");
  };

  const ratings = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      ratings.push(1);
      continue;
    }
    if (rating <= i && i - rating >= 0.5 && i - rating < 1) {
      ratings.push(0.5);
      continue;
    }
    if (rating <= i && i - rating <= 0.5 && i - rating >= 0) {
      ratings.push(1);
      continue;
    }
    if (rating <= i) {
      ratings.push(0);
      continue;
    }
  }

  return (
    <>
      <ModalWindow
        show={showModalWindow}
        setShow={(val) => setShowModalWindow(val)}
        body={
          localStorage.getItem(`${type}_${typeId}`) === "yes" ? (
            `You have already given ratings to this ${type}. Cannot give ratings to the same ${type} more than once!`
          ) : (
            <span
              className="ratting"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FontAwesomeIcon
                id="1"
                icon={icon[1]}
                style={{ color: color[1], cursor: "pointer" }}
                // onMouseEnter={handleMouseEnter}
                onClick={handleClickSetStar}
                size="lg"
              />
              <FontAwesomeIcon
                id="2"
                icon={icon[2]}
                style={{ color: color[2], cursor: "pointer" }}
                // onMouseEnter={handleMouseEnter}
                onClick={handleClickSetStar}
                size="lg"
              />
              <FontAwesomeIcon
                id="3"
                icon={icon[3]}
                style={{ color: color[3], cursor: "pointer" }}
                // onMouseEnter={handleMouseEnter}
                onClick={handleClickSetStar}
                size="lg"
              />
              <FontAwesomeIcon
                id="4"
                icon={icon[4]}
                style={{ color: color[4], cursor: "pointer" }}
                // onMouseEnter={handleMouseEnter}
                onClick={handleClickSetStar}
                size="lg"
              />
              <FontAwesomeIcon
                id="5"
                icon={icon[5]}
                style={{ color: color[5], cursor: "pointer" }}
                // onMouseEnter={handleMouseEnter}
                onClick={handleClickSetStar}
                size="lg"
              />
            </span>
          )
        }
        showFooter={
          localStorage.getItem(`${type}_${typeId}`) === "yes" ? false : true
        }
        footerBody={<button onClick={handleClickConfirm}>Confirm</button>}
        title={
          localStorage.getItem(`${type}_${typeId}`) === "yes"
            ? "ERROR: Cannot give rating!"
            : "Give your Rating"
        }
      />

      <button
        className="ratting"
        onClick={handleClickOpenModal}
        style={{ padding: 0, backgroundColor: "transparent" }}
      >
        {ratings.map((rat, i) => {
          return rat === 1 ? (
            <FontAwesomeIcon
              id={i}
              icon={faStarSolid}
              style={{ color: "#F16126" }}
              // size="lg"
              key={i}
            />
          ) : rat === 0.5 ? (
            <FontAwesomeIcon
              id={i}
              icon={faStarHalfStroke}
              style={{ color: "#F16126" }}
              key={i}
              // size="lg"
            />
          ) : (
            <FontAwesomeIcon
              id={i}
              icon={faStarRegular}
              style={{ color: "black" }}
              key={i}
              // size="lg"
            />
          );
        })}
      </button>
    </>
  );
};

export default Rating;
