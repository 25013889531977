import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingSpinner from "../component/sidebar/loading-spinner";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://parivartan.phpicoder.com/api/get_privacy_policy"
        );
        if (response.data.status) {
          setPrivacyPolicy(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  if (!privacyPolicy) {
    return <LoadingSpinner />;
  }
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Privacy Policy"} />
      <section className="instructor-single-section padding-tb section-bg">
        <div className="container">
          <div className="instructor-wrapper">
            <div
              // className="instructor-single-top"
              style={{
                padding: "60px 50px",
                backgroundColor: "#fff",
                boxShadow: "#8883 0px 0px 10px",
                borderRadius: "3px",
              }}
            >
              <div className="instructor-single-item d-flex flex-wrap justify-content-between">
                <div className="instructor-single-content">
                  <p
                    className="ins-desc"
                    dangerouslySetInnerHTML={{
                      __html: privacyPolicy.description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicy;
