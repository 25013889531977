import { BrowserRouter, Routes, Route } from "react-router-dom";
import "swiper/css";

import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import Home from "./page/home";
import TeamPage from "./page/team";
import TeacherPage from "./page/teacher";
import DonorsPage from "./page/donors";
import ContactPage from "./page/contact";
import CoursePage from "./page/course";

// import AboutPage from "./page/about";

// import BlogPage from "./page/blog";
// import BlogPageTwo from "./page/blog-2";
// import BlogPageThree from "./page/blog-3";
import BlogSingle from "./page/blog-single";
// import CartPage from "./page/cart-page";

import CourseSingle from "./page/course-single";
import ForgetPass from "./page/forgetpass";
// import HomeTwo from "./page/home-2";
// import HomeThree from "./page/home-3";
// import HomeFour from "./page/home-4";
// import HomeFive from "./page/home-5";
// import HomeSix from "./page/home-6";
// import HomeSeven from "./page/home-7";
import QualifiedStudentsPage from "./page/qualified-students-page";
import LoginPage from "./page/login";
import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
// import ShopPage from "./page/shop";
// import ShopDetails from "./page/shop-single";
import SignupPage from "./page/signup";
import Addmission from "./page/addmission";
import Receipt from "./page/receipt";

import TeamSingle from "./page/team-single";
import TeacherSingle from "./page/teacher-single";
import DonorsSingle from "./page/donors-single";

import AboutUs from "./page/about-us";
import TermsAndCondition from "./page/terms-and-condition";
import PrivacyPolicy from "./page/privacy-policy";
import HelpCenter from "./page/help-center";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="course" element={<CoursePage />} />
        <Route path="donors" element={<DonorsPage />} />

        {/* <Route path="home-2" element={<HomeTwo />} />
        <Route path="home-3" element={<HomeThree />} />
        <Route path="home-4" element={<HomeFour />} />
        <Route path="home-5" element={<HomeFive />} />
        <Route path="home-6" element={<HomeSix />} />
        <Route path="home-7" element={<HomeSeven />} /> */}

        <Route path="course-single" element={<CourseSingle />} />
        {/* <Route path="blog" element={<BlogPage />} />
         <Route path="blog-2" element={<BlogPageTwo />} />
        <Route path="blog-3" element={<BlogPageThree />} /> */}
        <Route path="blog-single/:id" element={<BlogSingle />} />
        {/* <Route path="about" element={<AboutPage />} /> */}
        <Route path="team" element={<TeamPage />} />
        <Route path="team-single" element={<TeamSingle />} />
        <Route path="qualified-students" element={<QualifiedStudentsPage />} />
        {/* <Route path="shop" element={<ShopPage />} />
        <Route path="shop-single" element={<ShopDetails />} />
        <Route path="cart-page" element={<CartPage />} /> */}
        <Route path="search-page" element={<SearchPage />} />
        <Route path="search-none" element={<SearchNone />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="addmission" element={<Addmission />} />
        <Route path="receipt" element={<Receipt />} />
        <Route path="teacher" element={<TeacherPage />} />
        <Route path="teacher-single" element={<TeacherSingle />} />
        <Route path="donor/:id" element={<DonorsSingle />} />

        <Route path="about-us" element={<AboutUs />} />
        <Route path="terms-and-condition" element={<TermsAndCondition />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="help-center" element={<HelpCenter />} />

        <Route path="forgetpass" element={<ForgetPass />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
